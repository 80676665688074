import React from 'react';
import './Welcome.css';
import { Link, Outlet } from 'react-router-dom';
import PetList from './PetList';
import Application from './Application';
import Logo from '../assets/paw-logo.png';
import Appointment from './Appointment';

function Welcome() {
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top" id="adminhead">
        <div className="container-fluid justify-content-evenly">
          <Link to={'/'} className="navbar-brand" href="#">
            <h3 className=" fw-bold fs-lg-1 fs-md-4">
              <img
                src={Logo}
                alt="logo"
                style={{ width: '2rem', height: '2rem', margin: '2px' }}
              />
              PAW Clinic & Supplies
            </h3>
          </Link>
        </div>
      </nav>

      <div className="container">
        <div className="container Admintables">
          <Outlet />
          <PetList />
          <Application />
          <Appointment />
        </div>
      </div>
    </>
  );
}

export default Welcome;
