import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './appoinment.css';
import banner from '../../assets/paws.jpg';
import { api } from '../../api';
import { useState, useEffect } from 'react';
import CreateAppointment from './CreateAppointment';

function Appointment() {
  const [appointmentlist, setAppointmentlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/appointment`;

  useEffect(() => {
    fetch(ENDPOINT, {
      method: 'GET',
      mode: api.MODE,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // const newTasks = [...tasks, ...result];
        // let newTasks = [...tasks];
        // newTasks = [...newTasks, ...data];
        setAppointmentlist([...data]);
      })
      .catch((error) => console.log('error', error));
  }, []);

  const bookAppointment = (
    id,
    firstname,
    lastname,
    address,
    city,
    province,
    zip,
    type_of_appointment,
    date_of_appointment,
    contactno1,
    contactno2,
    email
  ) => {
    console.log(`parent has been notified to add a task`);
    const data = {
      id: id,
      firstname: firstname,
      lastname: lastname,
      address: address,
      city: city,
      province: province,
      zip: zip,
      type_of_appointment: '',
      date_of_appointment: date_of_appointment,
      contactno1: contactno1,
      contactno2: contactno2,
      email: email,
    };
    fetch(ENDPOINT, {
      method: 'POST',
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        const bookApp = [...appointmentlist]; //copy
        bookApp.push(data);
        setAppointmentlist(bookApp);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <Navbar />
      <section className="my-5">
        <div
          className="container-fluid"
          style={{ padding: '0px', margin: '0px;' }}
        >
          <img
            src={banner}
            style={{ height: '300px', padding: '0px', margin: '0px;' }}
            alt="bg"
          />
        </div>
        <div className="container w-auto text-start">
          <div className="container bgcontainer p-5">
            <h5 className="my-3">
              We know how busy you are. Book an appointment to make sure the
              best use of your time.
            </h5>
            <div>
              <CreateAppointment bookAppointment={bookAppointment} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Appointment;
