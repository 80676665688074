import React from 'react';
import Logo from '../assets/paw-logo.png';
import '../component/Navbar.css';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top d-block">
        <div className="container-fluid justify-content-evenly">
          <a className="navbar-brand" href="#">
            <h3 className=" fw-bold">
              <img
                src={Logo}
                alt="logo"
                style={{ width: '2rem', height: '2rem', margin: '2px' }}
              />
              PAW Clinic & Supplies
            </h3>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa-solid fa-bars-staggered"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li className="nav-item">
              <Link to={`/`} className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                to={`/`}
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to={`/adopt`} className="dropdown-item" href="#">
                    Adoption
                  </Link>
                </li>
                <li>
                  <Link to={`/grooming`} className="dropdown-item" href="#">
                    Grooming
                  </Link>
                </li>
                <li>
                  <Link to={`/preventiveCare`} className="dropdown-item">
                    Preventive Care
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to={`/Appointment`} className="nav-link">
                Book An Appointment
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/faq`} className="nav-link">
                FAQs
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/ContactUs`} className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
