import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import care1 from '../../assets/care-1.jpg';
import care2 from '../../assets/care-2.jpg';
import care3 from '../../assets/care-3.jpg';
import care4 from '../../assets/care-4.jpg';
import care5 from '../../assets/care-5.jpg';

function PreventiveCare() {
  return (
    <>
      <Navbar />
      <section className="container-fluid my-5">
        <div className="container">
          <div className="container">
            <div>
              <h4>What Goes Into A Dog Health Preventive Care Regimen?</h4>
            </div>
            <p>
              Here at PAWS Clinic and Supplies, we believe that a sound
              preventive health care regimen is the easiest and most effective
              way to keep your pets healthy and happy. Many diseases are easily
              preventable and most can be effectively treated and cured if
              detected early enough. However, if allowed to develop and progress
              unchecked, many diseases can quickly become difficult and
              expensive to treat, and in some cases incurable. Therefore, we
              strongly encourage every dog owner to take a proactive approach to
              preventive care.
            </p>
            <div className="row">
              <div className="col col-md-8 col-lg-8">
                <p className="text-start">
                  Our comprehensive dog wellness programs typically consist of:
                </p>

                <ul className="text-start">
                  <li>
                    Education by our staff and various resources we can make
                    available to you (ie doctors, techs, handouts and
                    information available here on our website)
                  </li>
                  <li>
                    Things you can do at home to support weight loss, diet,
                    dental care, etc.
                  </li>
                </ul>
                <p className="text-start">
                  Our goal is to partner with you to ensure optimal health for
                  your dog. A healthy and happy canine companion will remain a
                  loyal and loving member of your family for many years to come.
                </p>
              </div>
              <div className="col col-md-4 col-lg-4">
                <img
                  src={care1}
                  style={{ width: '400px', height: '250px' }}
                  alt="care1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="row">
            <div className="col col-md-8 col-lg-8">
              <h4>Your Dog's Annual Physical Exam</h4>
              <p className="text-start">
                Taking an active role in a dog wellness program begins with
                visits to the veterinarian for periodic physical exams. The
                physical exam is a comprehensive assessment of your dog's
                health. Because your dog cannot talk, we rely on the owners to
                provide us with key information to help us to assess your dog's
                health. Our veterinarians will ask you specific questions and
                your answers will help guide the examination to assess overall
                health and reveal developing health issues that can easily go
                unnoticed. Based on age, health status and pre-existing
                conditions, some dogs require a physical exam annually, and
                others semi-annually (every six months). During the physical
                exam, your veterinarian will review various aspects of your
                dog's health, including:{' '}
              </p>
              <ul className="text-start">
                <li>Vaccination Status</li>
                <li>Dental Health</li>
                <li>Behavior </li>
                <li>Xrays - primarily for older dogs</li>
              </ul>
              <p className="text-start">
                After the physical exam, our veterinarian will discuss with you
                the findings and what, if anything, should be done to keep your
                dog in optimal health.
              </p>
            </div>
            <div className="col col-md-4 col-lg-4">
              <div className="container">
                <img
                  src={care2}
                  style={{
                    width: '400px',
                    height: '250px',
                    marginTop: '100px',
                  }}
                  alt="care2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="row">
            <div className="col col-md-4 col-lg-4">
              <div className="container">
                <img
                  src={care3}
                  style={{
                    width: '400px',
                    height: '250px',
                    marginTop: '100px',
                  }}
                  alt="care3"
                />
              </div>
            </div>
            <div className="col col-md-8 col-lg-8">
              <h4>Dental Care</h4>
              <p>
                Your pet's oral health is key for maintaining overall health.
                Dental disease is generally gradual and dogs adjust to living
                with oral pain and will not show signs they are uncomfortable.
                It is difficult for owners to know when their pet has dental
                disease or oral pain. For this reason, an important part of the
                physical exam is an oral exam. During this part of the exam, we
                will look at the teeth, tongue and oral cavity. The unfortunate
                reality is that periodontal disease affects most pets by age
                4-5.
              </p>
              <p>
                Some of the possible dental conditions which can affect your pet
                include:
              </p>
              <ul className="text-start">
                <li>Malocclusion</li>
                <li>Tooth Loss/broken teeth</li>
                <li>Gingivitis</li>
                <li>Mouth Sores and Ulcers</li>
                <li>Periodontal Disease</li>
              </ul>
              <p>
                This is why periodic and thorough dental care is essential to
                your pet's overall health. A thorough oral cleaning is something
                you should trust to your veterinarian every 6-12 months, but
                brushing can be performed on your canine companion in the
                comfort of your home. You can also help facilitate good dog
                health by providing toys and treats formulated to help
                strengthen and/or clean your pet's teeth and gums.
              </p>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="row">
            <div className="col col-md-8 col-lg-8">
              <h4>Protecting Your Pet From Fleas And Ticks</h4>
              <p>
                Fleas and ticks are a great nuisance to your pet and family.
                These pesky insects cause discomfort and can carry disease to
                your pet and possibly to your family. While these parasites are
                common, it is our goal that your pet never has to experiences
                the irritation fleas and ticks cause. We can work with you to
                set up a preventive plan to avoid infestation of your pet and
                your home. We will help to customize a plan for your pet based
                upon the pet's lifestyle, number and type of pets in the
                household. There are so many flea and tick products on the
                market. They are not all created equal and some are not
                effective and even dangerous. Our doctors and staff are very
                knowledgeable about these parasites and the best way to prevent
                them. Part of the annual physical exam is developing and
                managing the best flea and tick treatment plan for your pet.
              </p>
            </div>
            <div className="col col-md-4 col-lg-4">
              <div className="container">
                <img
                  src={care4}
                  style={{
                    width: '400px',
                    height: '250px',
                    marginTop: '50px',
                  }}
                  alt="care4"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <h4>What You Should Know About Vaccinations</h4>
          <div className="row mt-5">
            <div className="col col-md-4 col-lg-4">
              <div className="container">
                <img
                  src={care5}
                  style={{
                    width: '400px',
                    height: '250px',
                  }}
                  alt="care5"
                />
              </div>
            </div>

            <div className="col col-md-8 col-lg-8">
              <p className="text-start">
                Vaccinations are a foundational and critically important part of
                the preventive care plan. The vaccinations we recommend will
                prevent diseases that, while not common, are still a serious
                threat to the health, longevity and quality of life of your pet.
                The only reason they are not common is because of the excellent
                vaccines we have to protect your pet and hopefully the rest of
                the animals that your pet will come into contact with. When
                owners stop vaccinating, we see a resurgence of these very
                deadly diseases. Generally, a vaccine protocol consists of a
                series of immunizations given to your pet as a puppy, boosters
                at one year and then periodically throughout its lifetime.
                Although the rabies vaccine is the only vaccination currently
                required by law in most states, we strongly recommend making
                sure your canine companion is up to date on all of its core and
                non-core vaccinations. Your veterinarian can help determine a
                vaccination schedule as part of your pet health program based
                specifically on the lifestyle, geographic location and
                pre-existing medical conditions of your pet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default PreventiveCare;
