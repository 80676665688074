//import

const api = {
  ENDPOINT:
    process.env.NODE_ENV === 'production' || process.env.USER === 'ubuntu'
      ? 'https://grp14api.webpark.tech'
      : 'http://localhost:3002',
  MODE: 'cors',
  HEADERS: {
    'Content-type': 'application/json',
  },
};
//function

//export default
export { api };
