import './appoinment.css';
import { useState } from 'react';
import React from 'react';

function CreateAppointment({ bookAppointment }) {
  const [id, setID] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [zip, setZip] = useState('');
  const [type_of_appointment, setToa] = useState('');
  const [date_of_appointment, setDoa] = useState('');
  const [contactno1, setContactno1] = useState('');
  const [contactno2, setContactno2] = useState('');
  const [email, setEmail] = useState('');

  const addSubmit = (event) => {
    event.preventDefault();
    if (
      (!firstname,
      !lastname,
      !address,
      !city,
      !province,
      !zip,
      !type_of_appointment,
      !date_of_appointment,
      !contactno1,
      !contactno2,
      !email)
    )
      return;
    console.log(`test`);
    bookAppointment(
      id,
      firstname,
      lastname,
      address,
      city,
      province,
      zip,
      type_of_appointment,
      date_of_appointment,
      contactno1,
      contactno2,
      email
    );
    setID('');
    setFirstname('');
    setLastname('');
    setAddress('');
    setCity('');
    setProvince('');
    setZip('');
    setToa('');
    setDoa('');
    setContactno1('');
    setContactno2('');
    setEmail('');
  };
  return (
    <>
      <form
        onSubmit={addSubmit}
        name="form-appointment"
        id="form-appointment"
        className="text-start needs-validation"
        noValidate
      >
        <div className="row g-3">
          <div class="mb-3 col-12 col-md-6 col-lg-6">
            <label for="firstname" class="form-label">
              First Name:
            </label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              name="firstname"
              placeholder="Juan"
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
          <div class="mb-3 col-12 col-md-6 col-lg-6">
            <label for="exampleFormControlInput1" class="form-label">
              Last Name:
            </label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              name="lastname"
              placeholder=""
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>

        <div class="row g-3">
          <div class="col-sm-12 col-md-12">
            <label for="address" class="form-label">
              Address:
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="house no. street, village, barangay.."
              aria-label="address"
              id="address"
              name="address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <input
              type="text"
              class="form-control"
              placeholder="city"
              aria-label="city"
              id="city"
              name="city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <input
              type="text"
              class="form-control"
              placeholder="province"
              aria-label="province"
              id="province"
              name="province"
              value={province}
              onChange={(e) => {
                setProvince(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
          <div class="col-6 col-md-6 col-lg-3">
            <input
              type="text"
              class="form-control"
              placeholder="zip"
              aria-label="zip"
              id="zip"
              name="zip"
              value={zip}
              onChange={(e) => {
                setZip(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>

        <div class="row g-3">
          <label for="type_of_Appointment" class="form-label pt-3">
            Choose your type of appointment:
          </label>
          <div className="container">
            <select class="form-select" name="toa" aria-label="">
              <option name="toa">choose here....</option>
              <option value="Dental Care">Dental Care</option>
              <option value="Physical Examination">Physical Examination</option>
              <option value="Shelter Visit">Shelter Visit</option>
              <option value="Regular Consultation">Regular Consultation</option>
              <option value="Vaccination">Vaccination</option>
            </select>
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>

        <div className="row g-3">
          <div class="mb-3 col-12 col-md-12 col-lg-12">
            <label for="contactno" class="form-label">
              Date Of Appointment:
            </label>
            <input
              type="datetime-local"
              class="form-control"
              id="date_of_appoinment"
              name="date_of_appointment"
              value={date_of_appointment}
              onChange={(e) => {
                setDoa(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>

        <div className="row g-3">
          <div class="mb-3 col-12 col-md-12 col-lg-6">
            <label for="contactno" class="form-label">
              Contact Number:
            </label>
            <input
              type="text"
              class="form-control"
              id="contactno1"
              name="contactno1"
              placeholder="+6391234567"
              value={contactno1}
              onChange={(e) => {
                setContactno1(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
          <div class="mb-3 col-12 col-md-12 col-lg-6">
            <label for="altContact" class="form-label">
              Alternative Contact Number:
            </label>
            <input
              type="text"
              class="form-control"
              id="contactno2"
              placeholder="+6391234567"
              value={contactno2}
              onChange={(e) => {
                setContactno2(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>

        <div className="row g-3">
          <div class="mb-3 col-12 col-md-12 col-lg-12">
            <label for="email" class="form-label">
              Email Address:
            </label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              placeholder="+6391234567"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="invalid-feedback">Missing or invalid format.</div>
          </div>
        </div>
        <div className="container text-center">
          <button type="submit" class="btn btn-primary">
            Submit Appointment
          </button>
        </div>
      </form>
    </>
  );
}

export default CreateAppointment;
