//import
import React, { useState } from 'react';
//function
function Create({ addTask }) {
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [description, setDesc] = useState('');
  const [age, setAge] = useState('');
  const [image, setImage] = useState('');

  const addSubmit = (event) => {
    event.preventDefault();
    if ((!name, !description, !age, !image)) return;
    console.log(`test`);
    addTask(id, name, description, age, image);
    setID('');
    setName('');
    setDesc('');
    setAge('');
    setImage('');
  };
  return (
    <form
      onSubmit={addSubmit}
      name="form-adoption"
      className="text-start was-validated"
      id="form-adoption"
    >
      <div className="row row-cols-2 ">
        <div className="mb-3 col">
          <span className="" id="name">
            Name:
          </span>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div class="invalid-feedback">Missing or invalid format.</div>
        </div>
        <div className="col">
          <span className="" id="description">
            Description:
          </span>
          <input
            type="text"
            className="form-control"
            id="description"
            name="description"
            required
            value={description}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
          <div class="invalid-feedback">Missing or invalid format.</div>
        </div>
        <div className="col mb-3">
          <span className="" id="age">
            age:
          </span>
          <input
            type="text"
            className="form-control"
            id="age"
            name="age"
            required
            value={age}
            onChange={(e) => {
              setAge(e.target.value);
            }}
          />
          <div class="invalid-feedback">Missing or invalid format.</div>
        </div>
        <div className="col mb-3">
          <span className="" id="image" name="image" required>
            Image:
          </span>
          <input
            type="text"
            className="form-control"
            id="image"
            name="name"
            required
            value={image}
            onChange={(e) => {
              setImage(e.target.value);
            }}
          />
          <div class="invalid-feedback">Missing or invalid format.</div>
        </div>
        <div className="m-3">
          <button type="submit" className="btn btn-primary">
            <i className="fa-solid fa-plus"></i>ADD ANOTHER
          </button>
        </div>
      </div>
    </form>
  );
}

//export default
export default Create;
