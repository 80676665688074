import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AdoptPage from './component/adopt/AdoptPage';
import Faq from './component/FAQ/Faq';
import Grooming from './component/Grooming/Grooming';
import Welcome from './Admin/Welcome';
import PreventiveCare from './component/services/PreventiveCare';
import ContactUs from './component/ContactUs/ContactUs';
import Appointment from './component/services/Appointment';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/adopt',
    element: <AdoptPage />,
  },
  {
    path: '/faq',
    element: <Faq />,
  },
  {
    path: '/grooming',
    element: <Grooming />,
  },
  {
    path: '/admin',
    element: <Welcome />,
  },
  {
    path: '/preventiveCare',
    element: <PreventiveCare />,
  },
  {
    path: '/ContactUs',
    element: <ContactUs />,
  },
  {
    path: '/Appointment',
    element: <Appointment />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
