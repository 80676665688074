import React, { useEffect, useState } from 'react';
import { api } from '../api';
function Application() {
  const [applicationlist, setApplicationlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/application`;

  useEffect(() => {
    fetch(ENDPOINT, {
      method: 'GET',
      mode: api.MODE,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // const newTasks = [...tasks, ...result];
        // let newTasks = [...tasks];
        // newTasks = [...newTasks, ...data];
        setApplicationlist([...data]);
      })
      .catch((error) => console.log('error', error));
  }, []);
  const deleteTask = (id) => {
    fetch(`${ENDPOINT}/${id}`, {
      method: 'DELETE',
      mode: api.MODE,
      headers: api.HEADERS,
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...applicationlist]; //copy
        const filtered = newPet.filter((app) => app.id !== id);
        setApplicationlist(filtered);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div
      className="container mt-5 border border-1"
      style={{
        boxShadow: '0px 0px 15px -5px',
        borderRadius: '5px',
        fontSize: '0.8rem',
        backgroundColor: '#ffffff',
      }}
    >
      <div className="container">
        <div className="container my-5">
          <h3>Adoption Applications</h3>
        </div>
        <div className="container table-responsive">
          <table className="table border border-2">
            <thead>
              <tr>
                <th scope="col">Application ID</th>
                <th scope="col">Full Name</th>
                <th scope="col">Address</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Email Address</th>
                <th scope="col">Pet ID</th>
                <th scope="col">Pet to Adopt (Name)</th>
                <th scope="col">Date of Application</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '0.8rem' }}>
              {applicationlist.map((app) => {
                console.log(applicationlist);
                return (
                  <tr key={app.id}>
                    <th scope="row">{app.id}</th>
                    <td>{app.name}</td>
                    <td>{app.address}</td>
                    <td>{app.contact_number}</td>
                    <td>{app.email}</td>
                    <td>{app.pet_id}</td>
                    <td>{app.petname}</td>
                    <td>{app.date_of_application}</td>
                    <td>
                      {
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            deleteTask(app.id);
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="container m-3 d-flex justify-content-end">
          <div className="mx-2"></div>
        </div>
      </div>
    </div>
  );
}

export default Application;
