import React from 'react';
import about1 from '../../assets/about1.jpg';
import about2 from '../../assets/about2.jpg';
import about3 from '../../assets/about3.jpg';

function History() {
  return (
    <>
      <section className="container p-3">
        <div className="container mb-3">
          <h1 className=" fw-bold">About Us</h1>
          <div className="row">
            <div className="col-md-12 col-lg-4 g-5 ">
              <img
                src={about1}
                style={{ width: '400px', height: '250px' }}
                alt="care5"
              />
            </div>
            <div className="col-md-12 col-lg-8 g-5 align-items-center">
              <h3>Compassionate Care</h3>
              <p>
                Paw Vet Clinic is an Animal Clinic Open everyday 8:00Am to 5:00
                pm for your pets located at Block 34 lot 4 Victoria Villas
                Manila hills San Jose Rodriguez Rizal. Our Clinic is committed
                to excellence and a never-ending improvement of all that we do.
                We are committed to both doing things right while doing the
                right things. Clinic employees are always looking for a better
                way.
              </p>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-md-12 col-lg-8 g-5">
              <h3>Discipline</h3>
              <p>
                We are committed to defined regimens of thinking, behavior, and
                conduct in order to develop, control, and continuously improve
                our decision-making in relation to our strategies, standards,
                and team building for the sake of excellent results in all
                facets of our work.
              </p>
            </div>
            <div className="col-md-12 col-lg-4 g-5">
              <img
                src={about2}
                style={{ width: '400px', height: '250px' }}
                alt="care5"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-4 g-5">
              <img
                src={about3}
                style={{ width: '400px', height: '250px' }}
                alt="care5"
              />
            </div>
            <div className="col-md-12 col-lg-8 g-5">
              <h3>Safety</h3>
              <p>
                We value all animals over all other things. We are committed to
                take the care and make sure the safe work environment.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default History;
