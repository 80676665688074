import React from 'react';
import Cards from '../Cards';
import './AdoptPage.css';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { api } from '../../api';
import { useState } from 'react';

function AdoptPage() {
  const [adoptlist, setAdoptlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/application`;

  const addAdopt = (
    id,
    name,
    address,
    contact_number,
    email,
    pet_id,
    petname,
    date_of_application
  ) => {
    console.log(`parent has been notified to add a task`);
    const data = {
      name: name,
      address: address,
      contact_number: contact_number,
      email: email,
      pet_id: pet_id,
      petname: petname,
      date_of_application: date_of_application,
    };
    fetch(ENDPOINT, {
      method: 'POST',
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...adoptlist]; //copy
        newPet.push(data);
        setAdoptlist(newPet);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <>
      <Navbar />
      <div>
        <Cards addAdopt={addAdopt} />
      </div>

      <Footer />
    </>
  );
}

export default AdoptPage;
