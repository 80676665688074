import React from 'react';
import './Cards.css';
import { useState, useEffect } from 'react';
import { api } from '../api';

function Cards({ addAdopt }) {
  const [petlist, setPetlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/pet`;

  useEffect(() => {
    fetch(ENDPOINT, {
      method: 'GET',
      mode: api.MODE,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // const newTasks = [...tasks, ...result];
        // let newTasks = [...tasks];
        // newTasks = [...newTasks, ...data];
        setPetlist([...data]);
      })
      .catch((error) => console.log('error', error));
  }, []);

  const [OpenModal, SetOpenModal] = useState([]);
  const ContentModal = (petlist) => {
    SetOpenModal([petlist]);
  };
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact_number, setContact_number] = useState('');
  const [email, setEmail] = useState('');
  const [pet_id, setPet_Id] = useState('');
  const [petname, setPetname] = useState('');
  const [date_of_application, setDate] = useState('');

  const addSubmit = (event) => {
    event.preventDefault();
    if (
      (!name,
      !address,
      !contact_number,
      !email,
      !pet_id,
      !petname,
      !date_of_application)
    )
      return;
    console.log(`test`);
    addAdopt(
      id,
      name,
      address,
      contact_number,
      email,
      pet_id,
      petname,
      date_of_application
    );
    setID('');
    setName('');
    setAddress('');
    setContact_number('');
    setEmail('');
    setPet_Id('');
    setPetname('');
    setDate('');
  };
  return (
    <>
      <section className="my-5">
        {petlist.map((petlist) => {
          return (
            <div className="card-container" key={petlist.id}>
              <div className="row">
                <div className="col-md-3 col-lg-4">
                  <div className="image-container">
                    <img src={petlist.image} className=" m-2" alt="..." />
                  </div>
                </div>

                <div className="col-md-9 col-lg-8">
                  <div className="card-body px-3 mx-3">
                    <h5 className="card-title text-center p-3">
                      {petlist.name}
                    </h5>
                    <p className="card-text">{petlist.description}</p>
                    <p className="fs-6">Age: {petlist.age}</p>
                    <div className="container">
                      <button
                        className="btn btn-primary my-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => ContentModal(petlist)}
                      >
                        ADOPT ME
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      {OpenModal.map((pop) => {
        return (
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Adoption Application</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-4">
                      <h5>{pop.name}</h5>
                      <img
                        src={pop.image}
                        style={{ width: '200px', height: '200px' }}
                        alt="pet"
                      />
                      <p>Age: {pop.age}</p>
                    </div>
                    <div className="col-12 col-md-12 col-lg-8">
                      <form
                        onSubmit={addSubmit}
                        className="text-start was-validated"
                        id="form-adoption"
                      >
                        <div className="input-group input-group-sm mb-3">
                          <span
                            className="input-group-text bg-secondary"
                            id="name"
                          >
                            Full Name:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            required
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          <div class="invalid-feedback">
                            Missing or invalid format.
                          </div>
                        </div>
                        <div className="input-group input-group-sm mb-3">
                          <span
                            className="input-group-text bg-secondary"
                            id="address"
                          >
                            Complete Address:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            required
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                          <div class="invalid-feedback">
                            Missing or invalid format.
                          </div>
                        </div>
                        <div className="input-group input-group-sm mb-3">
                          <span
                            className="input-group-text bg-secondary"
                            id="Contact_number"
                          >
                            Contact Number:
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="contact_number"
                            name="contact"
                            required
                            value={contact_number}
                            onChange={(e) => {
                              setContact_number(e.target.value);
                            }}
                          />
                          <div class="invalid-feedback">
                            Missing or invalid format.
                          </div>
                        </div>
                        <div className="input-group input-group-sm mb-3">
                          <span
                            className="input-group-text bg-secondary"
                            id="email"
                            required
                          >
                            Email Address:
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="juandelacruz@email.com"
                            name="email"
                            required
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <div class="invalid-feedback">
                            Missing or invalid format.
                          </div>
                        </div>
                        <fieldset disabled>
                          <div className="input-group input-group-sm mb-3">
                            <span
                              className="input-group-text bg-secondary"
                              id="pet_id"
                            >
                              PET ID:
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="pet_id"
                              // placeholder={pop.id}
                              name="pet_id"
                              required
                              value={pop.id}
                              onChange={(e) => {
                                setPet_Id(e.target.value);
                              }}
                            />
                          </div>
                          <div className="input-group input-group-sm mb-3">
                            <span
                              className="input-group-text bg-secondary"
                              id="petname"
                            >
                              Name of Pet:
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              id="petname"
                              // placeholder={pop.name}
                              name="petname"
                              required
                              value={pop.name}
                              onChange={(e) => {
                                setPetname(e.target.value);
                              }}
                            />
                          </div>
                        </fieldset>
                        <div className="input-group input-group-sm mb-3">
                          <span
                            className="input-group-text bg-secondary"
                            id="date_of_application"
                          >
                            Date Of Application:
                          </span>
                          <input
                            type="date"
                            className="form-control"
                            id="date_of_pplication"
                            name="date_of_application"
                            required
                            value={date_of_application}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />

                          <div class="invalid-feedback">
                            Missing or invalid format.
                          </div>
                        </div>
                      </form>
                      <p className="fst-italic">
                        application process takes 3 days to 1 week. Screening is
                        conducted by our staff thru zoom meeting or phone calls.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    onClick={() => {
                      document.forms['form-adoption'].submit();
                    }}
                    className="btn btn-primary"
                  >
                    Submit Application
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Cards;
