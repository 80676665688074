import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import img from '../../assets/bg-faq.jpg';

function Faq() {
  return (
    <>
      <Navbar />
      <section className="container-fluid my-5">
        <div className="container">
          <h1>Frequently Asks Questions</h1>
        </div>

        <div className="accordion w-75 mx-auto" id="accordione">
          <img src={img} alt="faq" />
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                How can I adopt from PAW Clinic and Supplies?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordion"
            >
              <div className="accordion-body">
                <p>
                  Applicants can submit an application form from our website and
                  go through a screening process to ensure that our rescued
                  animals go to loving homes.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Why is there an adoption fee?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordion"
            >
              <div className="accordion-body">
                <p>
                  The adoption fee is a token of your commitment and and a
                  demonstration of your financial capacity to care for a pet.
                  P500 for cats / P1000 for dogs is a small price which already
                  covers your pet’s spay/neuter surgery, vaccinations and
                  tick+flea treatment.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Can my adoption application get denied?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  Yes. Some reasons for denied applications include: Not being
                  able to keep their pet indoors, incompatibility with the
                  household, plus other circumstances that may be damaging to
                  the health, safety, and happiness of our shelter animals.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Can I return my adopted pet if I change my mind?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  A pet is a lifetime commitment. However, if you truly can’t
                  keep your adopted pet, please don’t abandon them on the
                  streets or with strangers. Please return them to us so we can
                  find another home for them.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                How much is your Consultation Fee?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  Consultation Fee (Resident)- Php600.00(Regular hours at 9:30
                  am to 6:30 pm) Last call time is at 6:30pm
                  <br />
                  <br />
                  Emergency Consultation - Php1600-Php2500(Consults made outside
                  clinic hours, 6:30pm onwards)
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Do you conduct Home Service/Consultation?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  No. We only accept Walk-in Clients and Clients thru Online
                  Reservation or Appointment.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                How much does your grooming cost? and do you accept all kinds of
                pets?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  Grooming Fee cost depends on your pet size. full grooming
                  range from 500 - 700 php. As of now, we only accept dogs
                  and/or cats for grooming services.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                How can I contact your clinic?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  You can call or send us message to +639203358822, and/or send
                  us dm to our Social Media Platform @pawclinic (facebook,
                  Instagram and twitter).
                  <br />
                  You can also send us an e-mail. Just click the Contact Us link
                  on this page and you can send us messages or suggestions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Faq;
