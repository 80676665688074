import React from 'react';
import { useState, useEffect } from 'react';
import './Welcome.css';

import { api } from '../api';
import Create from './create';

function PetList() {
  const [petlist, setPetlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/pet`;
  console.log(ENDPOINT);
  useEffect(() => {
    fetch(ENDPOINT, {
      method: 'GET',
      mode: api.MODE,
      Headers: api.HEADERS,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // const newTasks = [...tasks, ...result];
        // let newTasks = [...tasks];
        // newTasks = [...newTasks, ...data];
        setPetlist([...data]);
      })
      .catch((error) => console.log('error', error));
  }, []);

  const addTask = (id, name, description, age, image) => {
    console.log(`parent has been notified to add a task`);
    const data = {
      name: name,
      description: description,
      age: age,
      image: 'adopt-1.png',
    };
    fetch(ENDPOINT, {
      method: 'POST',
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...petlist]; //copy
        newPet.push(data);
        setPetlist(newPet);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const deleteTask = (id) => {
    fetch(`${ENDPOINT}/${id}`, {
      method: 'DELETE',
      mode: api.MODE,
      headers: api.HEADERS,
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...petlist]; //copy
        const filtered = newPet.filter((pet) => pet.id !== id);
        setPetlist(filtered);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const [OpenModal, SetOpenModal] = useState([]);
  const ContentModal = (petlist) => {
    SetOpenModal([petlist]);
  };
  const [showEdit, setShowEdit] = useState('none');
  const [selectedEdit, setSelectedEdit] = useState(0);
  const handleEdit = (e) => {
    e.preventDefault();
    if (!e) return;
    const body = {
      name: e.target['name'].value,
      description: e.target['description'].value,
      age: e.target['age'].value,
      image: e.target['image'].value,
    };
    fetch(`${ENDPOINT}/${selectedEdit}`, {
      method: 'PUT',
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(body),
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...petlist]; //copy
        const filtered = newPet.filter((pet) => pet.id !== selectedEdit);
        body.id = selectedEdit;
        const updated = [...filtered, body];
        console.log(body);
        setPetlist(updated);
        setShowEdit('none');
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  // start of bootstrap
  return (
    <div
      className="container border border-1 mx-auto my-5"
      style={{
        boxShadow: '0px 0px 15px -5px',
        borderRadius: '5px',
        fontSize: '0.8rem',
        backgroundColor: '#ffffff',
      }}
    >
      <div className="container">
        <div className="container my-5">
          <h3>Pet for adoption List</h3>
        </div>
        <div className="container d-block text-start table-responsive">
          <div className="m-3">
            <Create addTask={addTask} />
          </div>

          <table className="table border border-2 text-center">
            <thead>
              <tr>
                <th scope="col" className="col-2">
                  ID PIC
                </th>
                <th scope="col" className="col-2">
                  Pet ID
                </th>
                <th scope="col" className="col-2">
                  Pet Name
                </th>
                <th scope="col" className="col-4">
                  Description
                </th>
                <th scope="col" className="col-2">
                  Age
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '0.8rem' }}>
              {console.log(petlist)}
              {petlist.map((pet) => {
                return (
                  <tr key={pet.id}>
                    <td>
                      <img
                        src={pet.image}
                        style={{ width: '50px', height: '50px' }}
                        alt="DP"
                      />
                    </td>
                    <th scope="row">{pet.id}</th>
                    <td>{pet.name}</td>
                    <td>{pet.description}</td>
                    <td>{pet.age}</td>
                    <td>
                      <div className="m-1">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            setShowEdit('block');
                            ContentModal(pet);
                            setSelectedEdit(pet.id);
                          }}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>
                      </div>
                      <div className="m-1">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            deleteTask(pet.id);
                            console.log(pet);
                          }}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        style={{ display: showEdit }}
        className="modal fade show"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">EDIT DETAILS</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setShowEdit('none');
                }}
              ></button>
            </div>
            {OpenModal.map((pet) => {
              return (
                <form
                  onSubmit={handleEdit}
                  name="adoption"
                  className="text-start was-validated"
                  id="form-adoption"
                >
                  <div className="modal-body">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        type="text"
                        hidden
                        value={selectedEdit}
                        onChange={() => {
                          console.log(setSelectedEdit);
                        }}
                      />

                      <span className="input-group-text bg-secondary" id="name">
                        Name:
                      </span>

                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        name="name"
                        placeholder={pet.name}
                      />

                      <div className="invalid-feedback">
                        Missing or invalid format.
                      </div>
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <span
                        className="input-group-text bg-secondary"
                        id="description"
                      >
                        Description:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="description"
                        required
                        name="description"
                        placeholder={pet.description}
                      />
                      <div className="invalid-feedback">
                        Missing or invalid format.
                      </div>
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text bg-secondary" id="age">
                        age:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="age"
                        required
                        name="age"
                        placeholder={pet.age}
                      />
                      <div className="invalid-feedback">
                        Missing or invalid format.
                      </div>
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <span
                        className="input-group-text bg-secondary"
                        id="image"
                        required
                      >
                        Image:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="image"
                        required
                        name="image"
                        placeholder={pet.image}
                      />
                      <div className="invalid-feedback">
                        Missing or invalid format.
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      UPDATE
                    </button>
                  </div>
                </form>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PetList;
