import React from 'react';
import './Home.css';
import HeroBanner from './HeroBanner';
import History from './History';

function Home() {
  return (
    <>
      <HeroBanner />

      <History />
    </>
  );
}

export default Home;
