import React from 'react';
import './App.css';
import Navbar from "./component/Navbar";
import AdoptPage from './component/adopt/AdoptPage';
import Home from './component/Home/Home';
import Footer from './component/Footer';




function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    <Footer/>
    
  
    </>
  );
  }

export default App;
