import React from "react";
import './HeroBanner.css';
import carousel1 from '../../assets/carousel-1.webp';
import carousel2 from '../../assets/carousel-5.jpg';
import carousel3 from '../../assets/carousel-6.webp';
import carousel4 from '../../assets/carousel-7.jpg';


function HeroBanner(){
    return(
        <section className='container my-5'>
            <div className="row">
            <div class="col-md-12 col-lg-6 mb-5">
                <div id="carouselExampleInterval" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div classNames="carousel-inner">
                        <div className="carousel-item active " data-bs-interval="2000">
                            <img src={carousel1} className="d-block" alt="..."/>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={carousel2} className="d-block" alt="..."/>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={carousel3} className="d-block" alt="..."/>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={carousel4} className="d-block" alt="..."/>
                        </div>
                    </div>
                    {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span classNames="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button> */}
                </div>
                </div>

            <div class="col-md-12 col-lg-6 mb-5">
                        <h4><strong>Our Mission</strong></h4>
                            <p class="text-muted">
                            We are pet care professionals who work as a team to help as many people and their pets in our community 
                            to have the best human-animal bond possible by offering optimal health, excellent veterinary care, 
                            and education in a low-stress environment.
                            </p>
                            <h4><strong>Our Vision</strong></h4>
                            <p class="text-muted">
                            Our vision is to provide the highest level of pet care through medical innovation, 
                            continued education, and advancements in animal healthcare. 
                            We will strengthen communication with our clients, and set a precedence of treating them and their pets responsibly, 
                            respectfully, and individually in a family environment, all while inspiring a culture of trust and compassion. 
                            Through our community involvement and support, we will promote and nurture the joy of the pet-human bond.
                            </p>
                </div>
                </div>
        </section>
    );
}
export default HeroBanner;
