import React from 'react';

import Footer from '../Footer';
import Navbar from '../Navbar';

function Grooming() {
  return (
    <>
      <Navbar />
      <section className="container-fluid my-5">
        <div className="container">
          <h2>Pet Grooming</h2>
          <p>
            We also take care of your pet’s grooming needs. Avail of our
            complete grooming package and dematting service or try our pet
            grooming services ala carte.
          </p>
        </div>
        <table className="table table-success table-striped w-75 mx-auto opacity-75">
          <thead>
            <tr>
              <th scope="col">
                <h4>Grooming Packages</h4>
              </th>
              <th scope="col">
                <h4>SRP</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Full Grooming for Small Breed (10kg below)</td>
              <td>PHP 550.00</td>
            </tr>
            <tr>
              <td>Full Grooming for Medium-Large Breed (11-39kg)</td>
              <td>PHP 750.00</td>
            </tr>
            <tr>
              <td>Full Grooming for Giant Size (40kg above)</td>
              <td>PHP 950.00</td>
            </tr>
            <tr>
              <td>Shampoo & Blow Dry for Small Breed</td>
              <td>PHP 250.00</td>
            </tr>
            <tr>
              <td>Shampoo & Blow Dry for Medium-Large Breed (11kg)</td>
              <td>PHP 450.00</td>
            </tr>
            <tr>
              <td>Medicated Bath D& Blow Dry for Small Breed</td>
              <td>PHP 250.00</td>
            </tr>
            <tr>
              <td>Medicated Bath & Blow Dry for Medium-Large Breed</td>
              <td>PHP 450.00</td>
            </tr>
            <tr>
              <td>Dematting</td>
              <td>PHP 450.00</td>
            </tr>
            <tr>
              <td>Nail Trim & Filing</td>
              <td>PHP 100.00</td>
            </tr>
            <tr>
              <td>Teeth Brushing</td>
              <td>PHP 75.00</td>
            </tr>
            <tr>
              <td>Eye Wash</td>
              <td>PHP 75.00</td>
            </tr>
            <tr>
              <td>Mouth Wash</td>
              <td>PHP 75.00</td>
            </tr>
            <tr>
              <td>Front line Application (excluding product)</td>
              <td>PHP 50.00</td>
            </tr>
            <tr>
              <td>Tear Stain Removal</td>
              <td>PHP 100.00</td>
            </tr>
            <tr>
              <td>Special Cut</td>
              <td>PHP 200.00</td>
            </tr>
          </tbody>
        </table>
      </section>
      <Footer />
    </>
  );
}

export default Grooming;
