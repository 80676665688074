import React, { useEffect, useState } from 'react';
import { api } from '../api';

function Appointment() {
  const [appointmentlist, setAppointmentlist] = useState([]);
  const ENDPOINT = `${api.ENDPOINT}/api/appointment`;

  useEffect(() => {
    fetch(ENDPOINT, {
      method: 'GET',
      mode: api.MODE,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // const newTasks = [...tasks, ...result];
        // let newTasks = [...tasks];
        // newTasks = [...newTasks, ...data];
        setAppointmentlist([...data]);
      })
      .catch((error) => console.log('error', error));
  }, []);

  const deleteTask = (id) => {
    fetch(`${ENDPOINT}/${id}`, {
      method: 'DELETE',
      mode: api.MODE,
      headers: api.HEADERS,
    })
      .then((data) => data.json())
      .then((data) => {
        const newPet = [...appointmentlist]; //copy
        const filtered = newPet.filter((appoint) => appoint.id !== id);
        setAppointmentlist(filtered);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div
      className="container my-5 border border-1"
      style={{
        boxShadow: '0px 0px 15px -5px',
        borderRadius: '5px',
        fontSize: '0.8rem',
        backgroundColor: '#ffffff',
      }}
    >
      <div className="container">
        <div className="container my-5">
          <h3>Appointments</h3>
        </div>
        <div className="container d-block text-start table-responsive">
          <table className="table border border-2 table-responsive">
            <thead>
              <tr>
                <th scope="col">Appointment ID</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Address</th>
                <th scope="col">City</th>
                <th scope="col">Province</th>
                <th scope="col">Zip Code</th>
                <th scope="col">Type of Appointment</th>
                <th scope="col">Date of Appointment</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Alternative Contact Number</th>
                <th scope="col">Email Address</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '0.8rem' }}>
              {appointmentlist.map((appoint) => {
                return (
                  <tr key={appointmentlist.id}>
                    <th scope="row">{appoint.id}</th>
                    <td>{appoint.firstname}</td>
                    <td>{appoint.lastname}</td>
                    <td>{appoint.address}</td>
                    <td>{appoint.city}</td>
                    <td>{appoint.province}</td>
                    <td>{appoint.zip}</td>
                    <td>{appoint.type_of_appointment}</td>
                    <td>{appoint.date_of_appointment}</td>
                    <td>{appoint.contactno1}</td>
                    <td>{appoint.contactno2}</td>
                    <td>{appoint.email}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          deleteTask(appoint.id);
                          console.log(appoint);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="container m-3 d-flex justify-content-end">
            <div className="mx-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
