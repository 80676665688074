import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './Contact.css';

function ContactUs() {
  return (
    <>
      <Navbar />
      <section className="my-5">
        <div className="container w-auto text-start">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.183458853161!2d121.12209163606167!3d14.758691839088275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397a4d5eca86fa9%3A0x87f0f341947f4c3!2sVictoria%20Villas%20at%20Metro%20Manila%20Hills!5e0!3m2!1sen!2sph!4v1679647869911!5m2!1sen!2sph"
                style={{ border: '0' }}
                title="map"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <div>
                <p>
                  <i className="fa-solid fa-location-dot"></i>&nbsp;Address:
                  Block 33 lot 4 Villas Manila Hills San Jose Rodriguez Rizal
                </p>
                <p>
                  <i className="fa-regular fa-clock"></i>&nbsp;Business Hours:
                  Open Mondays to Fridays from 8AM to 5PM
                </p>
                <p>
                  <i class="fa-solid fa-phone"></i>
                  &nbsp;Cellphone Number: +639203358822
                </p>
                <p>Or send as a direct message on our social media pages:</p>
                <div className="d-inline-flex px-3 mx-auto">
                  <p className="px-3">
                    <a href="https://facebook.com">
                      <i class="fa-brands fa-facebook"></i>
                      &nbsp;@PAWSClinic
                    </a>
                  </p>
                  <p className="px-3">
                    <a href="https://instagram.com">
                      <i class="fa-brands fa-instagram"></i>
                      &nbsp;@PAWSClinic
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="container bgcontainer p-5">
                <div className="row g-3">
                  <div class="mb-3 col-12 col-md-12 col-lg-12">
                    <label for="fullname" class="form-label">
                      Full Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="fullname"
                      placeholder="Juan Dela Cruz"
                    />
                  </div>
                </div>

                <div className="row g-3">
                  <div class="mb-3 col-12 col-md-12 col-lg-12">
                    <label for="contactno" class="form-label">
                      Contact Number:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="contactno1"
                      placeholder="+6391234567"
                    />
                  </div>
                </div>

                <div className="row g-3">
                  <div class="mb-3 col-12 col-md-12 col-lg-12">
                    <label for="email" class="form-label">
                      Email Address:
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="juandelacruz@email.com"
                    />
                  </div>
                </div>
                <div className="row g-3">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Message:</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div className="container text-center mt-3">
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default ContactUs;
