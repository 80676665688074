import React from 'react';
import '../component/Footer.css';


function Footer(){
    return(
        <footer className="p-3">
            <div className="container text-center">
                <div className="row">
                    
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mx-auto">
                        <p><i className="fa-solid fa-location-dot"></i>&nbsp;Address: Block 33 lot 4 Villas Manila Hills San Jose Rodriguez Rizal</p>
                        <p><i className="fa-regular fa-clock"></i>&nbsp;Business Hours: Open Mondays to Fridays from 8AM to 9PM</p>
                        
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mx-auto">
                        <h4>PAW Clinic and Supplies</h4>
                        <p>Copyrights &#169; 2023 All Rights Reserved</p>
                        <div className="d-inline-flex">
                            <a className="nav-link px-2"><i className="fa-brands fa-facebook"></i></a>
                            <a className="nav-link px-2"><i className="fa-brands fa-instagram"></i></a>
                            <a className="nav-link px-2"><i className="fa-brands fa-twitter"></i></a>
                        </div>
                        

                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                        <p>Mini Project 2 by :</p> 
                        <p>Adora delos Santos WD40P</p>
                        <p>Joven Curitao WD40P</p>
                        
                    </div>
                    

                </div>
            </div>
        </footer>
    );

}
export default Footer;